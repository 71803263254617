


.publicar{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    width: 50%;
    margin: auto;
  }
  
  .titulo{
    color: #dcc780;
  }
  
  
  .login{
    background-image: linear-gradient(to right, #e8d695, #d9c47c);
    color: #f5f7fa;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    width: 35%;
    height: 50px;
  
  }
  
  .criar{
    background-color: transparent;
    color: #dcc780;
    border: 2px solid #dcc780;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 10px;
    width: 35%;
    margin: 20px auto;
    height: 50px;
  
  }
  
  