.containter{
    margin-top: 200px;
  }
  .avatar{
    width: 10%;
    margin-top: 150px;
    position: relative;
    z-index: 100;
  }
  
  .modal-body{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -21%;
    border: none;
    box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
    border-radius: 10px;
    background-color: #fff;
  }
  
  
  .login{
    background-image: linear-gradient(to right, #e8d695, #d9c47c);
    color: #f5f7fa;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    width: 35%;
    height: 50px;
    margin-top: 20px;
    padding-left: 30px;
    position: relative;
  
  }
  
  .login::before {
    position: absolute;
    left: 10px;
    color: #ffffff;
  }
  
  .login.icon-1::before {
    content: '\f104';
  }
  
  .login.icon-2::before {
    content: '\f105';
  }
  
  
  .city {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    margin: 0.2rem;
    float: left;
  }
  
  
  .local {
    width: 1rem;
    height: auto;
    float: left;
    margin: 0.2rem;
  }
  
  .services {
    cursor: pointer;
    margin-top: 30px; margin-bottom: 30px;
    display: flex;
  width: 90%;
  margin:40px auto;
    .col-fixed {
        width: 20%;
    }
    .col-fixed-transportadoras {
      width: 25%;
      margin-bottom: 20px ;
    }
    .col-fixed-mais {
      width: 33%;
      margin-bottom: 20px ;
    }
  
  
    .service-item {
      font-size: 18px;
      border-radius: .5em;
      background-color: #fff;
      transition: all ease .3s;
      box-shadow: 0px 0px 10px 0 rgba(211, 181, 11, 0.25);
  
  
      .service-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1.1;
        padding: 0 1em;
        color: #d9c47c;
        text-decoration: none;
  
      }
      .service-titulo {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
      }
      .service-legenda {
        text-align: center;
        font-size: 12px;
        padding-top: 4px
      }
  
      &:hover {
      box-shadow: 0px 0px 10px 0 rgba(211, 181, 11, 0.25);
  
        transform: scale(1.03);
        transition: all ease .3s;
  
      }
    }
  
  
  
    .service-icone {
      display: inline-block;
      width: 2.5em;
      color: #d9c47c;
  
      height: 2em;
      margin-bottom: .5em;
      background-position: center;
      background-repeat: no-repeat;
  
      &.icone-autorizacao {
        background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-consulta {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M14.8284 6.34313L16.2426 4.92892L12 0.686279L7.75735 4.92892L9.17156 6.34313L12 3.51471L14.8284 6.34313Z' fill='currentColor' /%3E%3Cpath d='M4.92892 16.2426L6.34313 14.8284L3.51471 12L6.34313 9.17156L4.92892 7.75735L0.686279 12L4.92892 16.2426Z' fill='currentColor' /%3E%3Cpath d='M7.75735 19.0711L12 23.3137L16.2426 19.0711L14.8284 17.6568L12 20.4853L9.17156 17.6568L7.75735 19.0711Z' fill='currentColor' /%3E%3Cpath d='M17.6568 9.17156L20.4853 12L17.6568 14.8284L19.0711 16.2426L23.3137 12L19.0711 7.75735L17.6568 9.17156Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-fxd {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M7 18H17V16H7V18Z' fill='currentColor' /%3E%3Cpath d='M17 14H7V12H17V14Z' fill='currentColor' /%3E%3Cpath d='M7 10H11V8H7V10Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-divida {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M13 16H11V18H13V16Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V4ZM7 4H17V20H7L7 4Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-transporte {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M14.8284 12.4813L16.2426 11.067L12 6.82444L7.75732 11.0671L9.17154 12.4813L12 9.65286L14.8284 12.4813Z' fill='currentColor' /%3E%3Cpath d='M14.8284 16.7239L16.2426 15.3097L12 11.0671L7.75732 15.3097L9.17154 16.7239L12 13.8955L14.8284 16.7239Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23 4.77411C23 2.56497 21.2091 0.774109 19 0.774109H5C2.79086 0.774109 1 2.56497 1 4.77411V18.7741C1 20.9832 2.79086 22.7741 5 22.7741H19C21.2091 22.7741 23 20.9832 23 18.7741V4.77411ZM19 2.77411H5C3.89543 2.77411 3 3.66954 3 4.77411V18.7741C3 19.8787 3.89543 20.7741 5 20.7741H19C20.1046 20.7741 21 19.8787 21 18.7741V4.77411C21 3.66954 20.1046 2.77411 19 2.77411Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-danos {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M14.8285 14.8284L16.2427 13.4142L12.0001 9.17161L7.75745 13.4142L9.17166 14.8285L12.0001 12L14.8285 14.8284Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5C2.79086 1 1 2.79086 1 5V19ZM5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-convenio {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-eventos {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z' fill='currentColor' /%3E%3Cpath d='M9 9H11V17H9V9Z' fill='currentColor' /%3E%3Cpath d='M13 9H15V17H13V9Z' fill='currentColor' /%3E%3C/svg%3E");
      }
      &.icone-boletim {
        background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_101_239)'%3E%3Cpath d='M28.0375 13.2375L16.775 1.97503C15.8375 1.03753 14.1875 1.02503 13.2375 1.97503L1.98748 13.225C1.01248 14.2 1.01248 15.775 1.98748 16.75L13.2375 28C13.725 28.4875 14.3625 28.725 15 28.725C15.6375 28.725 16.275 28.4875 16.7625 28L28 16.7625C28.9875 15.8125 29 14.2375 28.0375 13.2375ZM15.0125 26.2375L3.76248 14.9875L15.0125 3.73753L26.2625 14.9875L15.0125 26.2375ZM9.99998 13.75V18.75H12.5V15H17.5V18.125L21.875 13.75L17.5 9.37503V12.5H11.25C10.5625 12.5 9.99998 13.0625 9.99998 13.75Z' fill='%23325432'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_101_239'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      }
      &.icone-registro {
        background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect width='30' height='30' fill='url(%23pattern0)'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0_1185_26' transform='scale(0.0166667)'/%3E%3C/pattern%3E%3Cimage id='image0_1185_26' width='60' height='60' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzElEQVR4nO2ZPWgUQRTHX1TwG9RGrEQ4Pwgkd/v+e8mZ5got/Ow8K00guXl7SgRTahPUTi211FIrSSy1jKV2aqcG/MBCGwUjBvVkEgNxssnteptkZpkfvOa4mdn/vre7b+ZP5PF4PB7PDJWRykYIxiCYngmFB/o3yitQGIag+U8oDFNegeDJAsGCCcojvUO9O1n4Z0yGf0Gwi/IGBI2Y7M6JjihvsOLHiwoWPKI8URwoboPgxxKCpytDlR2UFyAYWELsbETop7zAisdbChaMUR7oPN+5hRVPtRLMwt/7Bvu2kutwnU8nyO5c1Mh1WPh+UsGs+B65TOFCYT0r/pJYsPDX6kB1A7lKIMHJFOU8K7rOJ8hVoHA3tWDhO+Qi1dHqOgg+pRas+LMeS64RqvBwEiG1Wm2teWMCCQ6Ra0BwO2mpLih9hVvkFKO0BoIPRrkeX+zv+kVlVMNHPQe5QqjCvjSfm7jPVyDBQXIFCG6mbShiGpQb5Aqs+LWxEzrVaoxuK43neJJcIGyEgZHdqe4z3ZtbjYNgEwTf5o8t18slsh1WfO1/t30x28irZDtQeDn/okMVnk08NkK/8bJ7QTZTrpf3GRma7jrXtb3No6ADZCssfDltK5mg1bxEtgLBs6wFQ+Ep2UgwGOyG4HfmggXNnnrPHrINKIwsh9i/L6+L5Ihv1MwoJtzwjSSz59gu/wlL+UbZiY5c8Y2aGYUd/lMxplkIG+H+ductRaW9VvpPMHwjVvw8q7l1a2md/8TCD41MXMlqbj2XcTPHyTbfqBSVilnNr7eHVvlPbPhGLPwm6zUgeGWN/8TmsYzC9azX0Ec9VvhPhZiDN65zJet19GGeFf5TYPpGCu+JqGMZlupg4Xcr5j8hwjF9oLYCjUW7ndhkqMKjbQtmxW9XXUxy0brKvOBU6DLRn5tVz16L0NcYSHAknTqPx+PxeDy0gvwBkBLRetzpVQsAAAAASUVORK5CYII='/%3E%3C/defs%3E%3C/svg%3E%0A");
      }
      &.icone-multas {
        background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_101_228)'%3E%3Cpath d='M23.75 6.25V23.75H6.25V6.25H23.75ZM23.75 3.75H6.25C4.875 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75Z' fill='%23325432'/%3E%3Cpath d='M17.5 21.25H8.75V18.75H17.5V21.25ZM21.25 16.25H8.75V13.75H21.25V16.25ZM21.25 11.25H8.75V8.75H21.25V11.25Z' fill='%23325432'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_101_228'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      }
      &.icone-licitacoes {
        background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg clip-path='url(%23clip0_1222_23)'%3E%3Crect width='30' height='30' fill='url(%23pattern0)'/%3E%3C/g%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0_1222_23' transform='scale(0.0333333)'/%3E%3C/pattern%3E%3CclipPath id='clip0_1222_23'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3Cimage id='image0_1222_23' width='30' height='30' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/0lEQVR4nO2VP2sUURTFX2KhqYIQG0VIsYmFJDPnvCkiFrG0zr2PNTaCaGkQDUmZLlXiF7ARFbTwO4iokFZSpEmTKIIoESViEDXyxpklvJ2EmdnNpsmFy/5h3/3de/ecN8Ycx1GGVeug+EjlbpmE4DcES2bB9HcEhuBzWWiWX7PXF5M3Jk/VBlP4pwoYggSKtfS9YiV28ZmegO20HRq7PnaayldZI+vjzfHRnoCNMaZxtXGSwmfZ5F/slL1cDyzlGsjBaSyYfiofZOe3KwkOikdJMzlvjOlLXHKBwtsQPKXwQyFY7JW25pV3fePOuROVpt63KYcGBDcpfAzBRqsB4VaY3maVwHC4ROU7CH5S+ZbC5djFGrnoXPjbqBkNQzBP5U7RNsqC++BwD4JfB1hnk8LnfpWRi+L8oBU7UxsMwcOKF4dvZDE9ew1n64MV3yuDFT/8pnwWuaAUmMJZKj+l4ghFUySioLgXU93/uBV596kvHS2Vf8NCOehQwFDc2avYnoF5QKEQnCl7pzsTC755D4eQInD63f8NdQ6Op+KRIggUbwJ1r6RTOzvYNXFNuImB/PMe8MvAz69T8LQd6go4kugiFKtlC1m197sjLuF2mUJ+Uijmwuu2U1U/aVt1gXU6eUi0IgdZtbfKerYthVvZdVpPXDXB770NKyCPwxxK/AMMSGNUJPMYrQAAAABJRU5ErkJggg=='/%3E%3C/defs%3E%3C/svg%3E%0A");
      }
    }
  
    @media(min-width:1025px) {
      gap: 20px;
      .service-item {width: calc(16.666% - 20px);}
    }
  
    @media(max-width:1024.9px) and (min-width:768px){
      gap: 15px; padding-left: 20px; padding-right: 20px;
      .service-item {width: calc(25% - 15px);}
    }
  
    @media(max-width:767.9px) and (min-width:480.1px){
      gap: 10px; padding-left: 15px; padding-right: 15px;
      .service-item {width: calc(33.333% - 10px);}
    }
  
    @media(max-width:480px){
      gap: 10px; padding-left: 10px; padding-right: 10px;
      .service-item {width: calc(50% - 10px);}
    }
  }
  
  