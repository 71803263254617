.demo10 {
    padding: 30px 0;
    height: 100rem;
    margin-top: 90px;
  }
  
  .pricingTable10 {
  
  
    box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  
    text-align: center
  }
  
  .pricingTable10 .pricingTable-header {
    padding: 30px 0;
    background: #dcc780;
    position: relative;
    transition: all .3s ease 0s
  }
  .pricingTable102 .pricingTable-header {
    padding: 30px 0;
    background: #dcc780;
    position: relative;
    transition: all .3s ease 0s
  }
  .pricingTable10:hover .pricingTable-header {
    background: #af9c5c
  }

  
  .pricingTable10 .pricingTable-header:after,
  .pricingTable10 .pricingTable-header:before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #d9d9d8;
    position: absolute;
    bottom: 12px
  }
  
  .pricingTable10 .pricingTable-header:before {
    left: 40px
  }
  
  .pricingTable10 .pricingTable-header:after {
    right: 40px
  }
  
  .pricingTable10 .heading {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0
  }
  
  .pricingTable10 .price-value {
    display: inline-block;
    position: relative;
    font-size: 55px;
    font-weight: 700;
    color: #fff;
    transition: all .3s ease 0s
  }
  
  .pricingTable10:hover .price-value {
    color: #fff
  }
  
  .pricingTable10 .currency {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: -18px;
    left: -31px;
  }
  
  .pricingTable10 .month {
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: -30px;
    text-transform: uppercase
  }
  
  .pricingTable10 .pricing-content {
    padding-top: 50px;
    background: #fff;
    position: relative
  }
  
  .pricingTable10 .pricing-content:after,
  .pricingTable10 .pricing-content:before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #7c7c7c;
    position: absolute;
    top: 12px
  }
  
  .pricingTable10 .pricing-content:before {
    left: 40px
  }
  
  .pricingTable10 .pricing-content:after {
    right: 40px
  }
  
  .pricingTable10 .pricing-content ul {
    padding: 0 20px;
    margin: 0;
    list-style: none
  }
  
  .pricingTable10 .pricing-content ul:after,
  .pricingTable10 .pricing-content ul:before {
    content: "";
    width: 8px;
    height: 46px;
    border-radius: 3px;
    background: linear-gradient(to bottom, #818282 50%, #727373 50%);
    position: absolute;
    top: -22px;
    z-index: 1;
    box-shadow: 0 0 5px #707070;
    transition: all .3s ease 0s
  }
  
  .pricingTable10:hover .pricing-content ul:after,
  .pricingTable10:hover .pricing-content ul:before {
    background: linear-gradient(to bottom, #dbcc40 50%, #cc8f34 50%)
  }
  
  .pricingTable10 .pricing-content ul:before {
    left: 44px
  }
  
  .pricingTable10 .pricing-content ul:after {
    right: 44px
  }
  
  .pricingTable10 .pricing-content ul li {
    font-size: 15px;
    font-weight: 700;
    color: #777473;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d8
  }
  
  .pricingTable10 .pricing-content ul li:last-child {
    border-bottom: none
  }
  
  .pricingTable10 .read {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    background: #d9d9d8;
    padding: 8px 25px;
    margin: 30px 0;
    transition: all .3s ease 0s
  }
  
  .pricingTable10 .read:hover {
    text-decoration: none
  }
  
  .pricingTable10:hover .read {
    background: #af9c5c
  }
  





  .pricingTable20 {
  
  
    box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  
    text-align: center
  }
  
  .pricingTable20 .pricingTable-header {
    padding: 30px 0;
    background: #dcc780;
    position: relative;
    transition: all .3s ease 0s
  }
  
 

  @media screen and (max-width:990px) {
    .pricingTable20 {
      margin-bottom: 25px
    }
  }
  




  @media screen and (max-width:990px) {
    .pricingTable10 {
      margin-bottom: 25px
    }
  }
  




































  
  .pricingTable20 {
  
  
    box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  
    text-align: center
  }
  
  .pricingTable20 .pricingTable-header {
    padding: 30px 0;
    background: #dcc780;
    position: relative;
    transition: all .3s ease 0s
  }
  


  .pricingTable20 .heading {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0
  }
  
  .pricingTable20 .price-value {
    display: inline-block;
    position: relative;
    font-size: 55px;
    font-weight: 700;
    color: #fff;
    transition: all .3s ease 0s
  }

  .pricingTable20 .currency {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: -18px;
    left: -31px;
  }
  
  .pricingTable20 .month {
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: -30px;
    text-transform: uppercase
  }
  
  .pricingTable20 .pricing-content {
    padding-top: 50px;
    background: #fff;
    position: relative
  }
  
  
  

  
  .pricingTable20 .pricing-content ul {
    padding: 0 20px;
    margin: 0;
    list-style: none
  }
  


  

  
  .pricingTable20 .pricing-content ul li {
    font-size: 15px;
    font-weight: 700;
    color: #777473;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d8
  }
  

  .pricingTable20 .read {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    background: #d9d9d8;
    padding: 8px 25px;
    margin: 30px 0;
    transition: all .3s ease 0s
  }
  
  
  .pricingTable20:hover .read {
    background: #af9c5c
  }
  










  