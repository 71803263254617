
.header {
    width: 100%;
    height: auto;
    background-color: #f5f7fa;
  
    z-index: 1;



    align-items: center;
    background-color: #fff;
    box-shadow: 0.1rem 0.3rem 0.7rem rgba(0,0,0,.151);
    display: flex;

    flex-direction: row;
    /* flex-flow: column; */
    justify-content: space-between;




  }
  
  .button{
    background-image: linear-gradient(to right, #e8d695, #d9c47c);
    color: #f5f7fa;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    width: 150px;
    height: 50px;
  
  }
  
  .header-fake {
    width: 100%;
    height: 17rem;
    background-color: #f5f7fa;
  }
  
  .cont-header {
    background-color: white;
    width: 100%;
    /* height: 7rem; */
    padding: 1rem;
    box-shadow: 0.1rem 0.3rem 0.7rem rgba(0, 0, 0, 0.151);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .cont-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: white;
    width: 95%;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 1rem;
    border-radius: 1.5rem;
    box-shadow: 0.1rem 0.3rem 0.7rem rgba(0, 0, 0, 0.151);
    transition: all 0.2s;
  }
  
  .logo {
    height: 5rem;
    padding-top: 10px;
    margin-left: 20px;
    width: auto;
    cursor: pointer;
  }
  
  .menu {
    height: 4rem;
    margin-right: 20px;
    width: auto;
    padding-bottom: 5px;
    cursor: pointer;
  }
  
  .header{
    display: flex;
    justify-content: space-around;
  }
  
  input {
    border: none;
    width: 100%;
    outline: none;
    /* text-align: center; */
    font-size: 2rem;
  }
  
  input::placeholder {
    color: #dcc780;
    opacity: 1;
    /* Firefox */
  }
  
  label {
    width: 100%;
    padding: 1rem 5% 1rem 5%;
    border: 2px solid #dcc780;
    border-radius: 0.5rem;
    height: 15px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
  }
  
  form {
    width: 50%;
    text-align: center;
    height: auto;
  }
  .dropbtn {
  display: block;
  }
  .dropbtn:hover {
    background-color: #d9c37c2f;
  }
  .dropdown {
    position: relative;
 
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    right: -15px;
    z-index: 100;
  }
  
  .dropdown-content a {
    color: #dcc780;
    border: 1px solid #77777731;
    padding: 15px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #acacac2f;}

  .dropdown:hover .dropdown-content {
    display: block;
    
  }
  .dropdown:hover .dropbtn {background-color: #cac7be2f;}
  
