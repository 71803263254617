.circle-image{
    display: inline-block;
    overflow: hidden;
    width: 33%;
    padding-bottom: 33%;
    height: 0;
    position: relative;
}
.circle-image img{
  width:100%;
  height:100%;
  position: absolute;
  clip-path: circle(50%);
  object-fit: cover;
}

.player {
  width: 100vw;
  height: 100vh;
  .back {
    position: absolute;
    padding: 2rem;
    z-index: 1;
    svg {
      font-size: 3rem;
      cursor: pointer;
    }
  }
  video {
    height: 100%;
    width: 100%;
    
  }
}

.modal-content-header{
  position: sticky;
  top: 0;
  background-color: #fff; /* Adapte a cor de fundo conforme necessário */
  z-index: 1; /* Certifique-se de que o cabeçalho e o corpo estejam acima do conteúdo rolável */
}

.modal-content {
  max-height: 94vh;
  overflow-y: auto;
}

.card3 {
  --border-width: 6px;
  position: relative;
  border-radius: 2rem;

  overflow: hidden;
  cursor: pointer;
}

.border3 {
  position: absolute;
  z-index: 1;
  inset: 0;
  padding: var(--border-width);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  border-radius: inherit;

  transition: opacity .25s ease-in-out;
}

.border3::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 200%;
  min-height: 200%;
  aspect-ratio: 1/1;
  background: conic-gradient(from 0deg, #dad1b6, #DCC780, #dbb741, black);
  transform: translate(-50%, -50%) rotate(0deg);
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.card3 .border3 {
  opacity: 1;
}

