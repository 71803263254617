
.mycontainer {
  /* width: 100%;
  height: auto; */
  text-align: center;
}

.content{
  margin-top: 18rem;
}

.body{
  background-color: #f5f7fa;
}

.card-ac {
   background-image: url(https://images.unsplash.com/photo-1612367939117-84bc4cd00c48?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bXVsaGVyJTIwc2Vuc3VhbHxlbnwwfHwwfHx8MA%3D%3D);
   background-repeat: no-repeat;
   background-size: auto 30rem;
   background-position: center;
  width: 19rem;
  height: 25rem;
  border-radius: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  cursor: pointer;
  margin: 1rem;
}

.card1 {
  background-image: url('https://i.ebayimg.com/images/g/RV0AAOSwBPRcLYnp/s-l1600.jpg');
  background-repeat: no-repeat;
  background-size: auto 30rem;
  background-position: center;
}

.card2 {
  background-image: url('https://preview.redd.it/the-sexy-ana-de-armas-v0-0oppixvvll6a1.jpg?width=640&crop=smart&auto=webp&s=4fa9031c8ed6d43dabde0ab5023bb75c5bbdbf98');
  background-repeat: no-repeat;
  background-size: auto 30rem;
  background-position: center;
}

.card3 {
  background-image: url('https://qph.cf2.quoracdn.net/main-qimg-1324a69f17fec1d58bef85fde341c524-lq');
  background-repeat: no-repeat;
  background-size: auto 30rem;
  background-position: center;
}
.contact {
  background-color: rgba(0, 0, 0, 0.349);
  backdrop-filter: blur(20px);
  width: 17rem;
  height: 6rem;
  border-radius: 1rem;
  margin-bottom: 2.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.name {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0.2rem;
}

.city {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.2rem;
  float: left;
}

.local {
  width: 1rem;
  height: auto;
  float: left;
  margin: 0.2rem;
}

.modal-open{
  display: block;
  backdrop-filter: blur(8px);
  background-color: rgb(28 27 27 / 50%);
  margin: auto;
}

.modal-header{
  background-image: url('https://kanto.legiaodosherois.com.br/w760-h398-cfill/wp-content/uploads/2022/09/legiao_Z_os15ABdgNv.png.webp');
  background-repeat: none;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: baseline;
}

.avatar{
  width: 30%;
  margin-top: -55px;
  position: relative;
  z-index: 100;
}

.modal-body{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -21%;
  border: none;
  box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  border-radius: 10px;
  background-color: #fff;
}


.filter-header{
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.filter-body{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 20px;
  background-color: #fff;
}

#slider {
	width: 95%;
	height: 17px;
	position: relative;
	margin: auto;
	background: transparent;

  border: none;
}

#slider .bar {
	width: 95%;
	height: 5px;
	background: #dcc780;
	position: relative;
	top: -4px;
	left: 4px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
}

#slider .highlight {
	height: 2px;
	position: absolute;
	width: 388px;
	top: 6px;
	left: 6px;

	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;

	background: rgba(255, 255, 255, 0.25);
}

input[type="range"] {

	background-color: black;
	height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	position: relative;
	top: 0px;
	z-index: 1;
	width: 11px;
	height: 11px;
	cursor: pointer;
	-webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.6);
	-moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.6);
	box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.6);
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
	background-color: orange;
}

input[type="range"]:hover ~ #rangevalue,input[type="range"]:active ~ #rangevalue {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
	top: -75px;
}

/* Tool Tip */
#rangevalue {
	color: white;
	font-size: 10px;
	text-align: center;
	font-family:  Arial, sans-serif;
	display: block;
	color: #fff;
	margin: 20px 0;
	position: relative;
	left: 44.5%;
	padding: 6px 12px;
	border: 1px solid black;

	-webkit-box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.2), 0px 2px 4px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.2), 0px 2px 4px 0px rgba(0,0,0,0.4);
	box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.2), 0px 2px 4px 0px rgba(0,0,0,0.4);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#222931), color-stop(100%,#181D21));

	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	width: 18px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;

	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
	top: -95px;
}

input[type="range"]:focus{
  outline:none;
}



.responsive-img {
  max-width: 100%;
  height: auto;
}


.modal-close{
  display: none;
}

.fade {
  transition: opacity .15s linear;
}
.whats {
  width: 2rem;
  height: auto;
  border-radius: 1rem;
  margin-left: 2rem;
}

.filters {
  display: flex;
  margin: 115px 30px 10px;
  flex-direction: row;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0.2rem 0.3rem 0.7rem rgba(0, 0, 0, 0.267);
  position: absolute;
  z-index: 50;

}

.filters > div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  box-sizing: border-box;
  margin: 5px;
}

.filters select,
.filters input{
  width: 100%;
  box-sizing: border-box;
}

.filters img {
  max-width: 100%;
  height: auto;
}

.logo {
  height: 4rem;
  margin-left: 20px;
  width: auto;
  cursor: pointer;
}

.menu {
  height: 4rem;
  margin-right: 20px;
  width: auto;
  cursor: pointer;
}

.header{
  display: flex;
  justify-content: space-around;
}

input {
  border: none;
  width: 100%;
  outline: none;

  font-size: 2rem;
}

input::placeholder {
  color: #dcc780;
  opacity: 1;

}

label {
  color: #dcc780;
  border: 2px solid #dcc780;
  width: 15%;
  height: 50px;
  border-radius: 0.5rem;
  display: flex;
}

form {
  width: 50%;
  text-align: center;
  height: auto;
}
.submit {
  right: 2%;
  top: 20%;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-size: cover;
  background-position: center;

  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}


.gold-txt {
  color: #dcc780;
  border: 2px solid #dcc780;
  width: 15%;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 10px;
  height: 50px;
  border-radius: 0.5rem;
}

.gold-bg {
  background-color: #dcc780;
}


.normal-txt {
  color: #707070;
  text-align: center;
  font-weight: bold;
  padding-right: 10px;
  font-size: large;
}

.mybutton {
  color: white;
  border: none;
  padding: 0.7rem;
  font-size: 1.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.mybutton:focus {
  border: none;
  outline: none;
}




.responsivecell{
  width: 45%;
   top: 30%;
    left: 27.5%
}
@media (max-width: 900px) {
  .responsivecell{
    width: 75%;
    top: 30%;
     left:  13.5%
  }
}







#inputEstado {
  color: #dcc780;
  border: 2px solid #dcc780;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 10px;
  height: 50px;
  border-radius: 0.5rem;
}

#inputIdade {
  color: #dcc780;
  padding-left: 10px;
  align-items: center;
  font-size: 1.5rem;
  border: 2px solid #dcc780;
  border-radius: 0.5rem;
  height: 50px;
}

#inputEstado:focus {
  border-color: #dcc780;
  box-shadow: 0 1px 1px #dcc780 inset, 0 0 8px #dcc780;
  outline: 0 none;
}


#filters img {
  width: 5rem;
  margin-left: 10px;
  height: auto;
}

.master-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.mydrop {
  width: 0;
  transition: all 0.2s;
}

.cont-category {
  width: 100%;
  height: 100%;
  margin-top: 10rem;
}

.categorys {
  width: 100%;
  height: auto;
  text-align: center;
}

.open-drop {
  width: 25%;
}

.close-cont {
  width: 75%;
}

.close-filters {
  width: 71%;
}

.nocolor {
  background-color: rgba(0, 0, 0, 0);
}

.rigth {
  align-items: flex-end;
}




@media(min-width:940px){

  .filters {
width: 95%;
  }
}

@media(max-width:940px){


.content{
  margin-top: 23rem;
}

}


@media(max-width:600px){


.content{
  margin-top: 26rem;
}

}

  /* ==========================================================================
     #FORM
     ========================================================================== */
     input {
      outline: none;
      margin: 0;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      width: 100%;
      font-size: 14px;
      font-family: inherit;
    }
    
    .input-group {
      position: relative;
      margin-bottom: 30px;
      border-bottom: 2px solid #ccc;
    }
    
    .input-icon {
      position: absolute;
      font-size: 18px;
      color: #ccc;
      right: 8px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
    }
    
    .input--style-1 {
      padding: 9px 0;
      color: #666;
    }
    
    .input--style-1::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #555;
    }
    
    .input--style-1:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #555;
      opacity: 1;
    }
    
    .input--style-1::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #555;
      opacity: 1;
    }
    
    .input--style-1:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #555;
    }
    
    .input--style-1:-ms-input-placeholder {
      /* Microsoft Edge */
      color: #555;
    }
    
    /* ==========================================================================
       #SELECT2
       ========================================================================== */
    .select--no-search .select2-search {
      display: none !important;
    }
    
    .rs-select2 .select2-container {
      width: 100% !important;
      outline: none;
    }
    
    .rs-select2 .select2-container .select2-selection--single {
      outline: none;
      border: none;
      height: 34px;
    }
    
    .rs-select2 .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: 34px;
      padding-left: 0;
      color: #555;
    }
    
    .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow {
      height: 32px;
      right: 4px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    
    .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow b {
      display: none;
    }
    
    .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow:after {
      font-family: "Material-Design-Iconic-Font";
      content: '\f2f9';
      font-size: 18px;
      color: #ccc;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    
    .rs-select2 .select2-container.select2-container--open .select2-selection--single .select2-selection__arrow::after {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
    
    .select2-container--open .select2-dropdown--below {
      border: none;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #e0e0e0;
      margin-top: 5px;
      overflow: hidden;
    }
    
    /* ==========================================================================
       #TITLE
       ========================================================================== */
    .title {
      margin-bottom: 37px;
    }
    
    /* ==========================================================================
       #CARD
       ========================================================================== */
    .card {
      overflow: hidden;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      background: #fff;
    }
    
    .card-1 {
      -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    }
    
    .card-1 .card-heading {
  
      padding-top: 210px;
    }
    
    .card-1 .card-body {
      padding: 0 90px;
      padding-top: 55px;
      padding-bottom: 65px;
    }
    
    @media (max-width: 767px) {
      .card-1 .card-body {
        padding: 0 40px;
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  
  
  
  
    .checkbox-wrapper-2 .ikxBAC {
      appearance: none;
      background-color: #dfe1e4;
      border-radius: 72px;
      border-style: none;
      flex-shrink: 0;
      height: 20px;
      margin: 0;
      position: relative;
      width: 30px;
    }
  
    .checkbox-wrapper-2 .ikxBAC::before {
      bottom: -6px;
      content: "";
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }
  
    .checkbox-wrapper-2 .ikxBAC,
    .checkbox-wrapper-2 .ikxBAC::after {
      transition: all 100ms ease-out;
    }
  
    .checkbox-wrapper-2 .ikxBAC::after {
      background-color: #fff;
      border-radius: 50%;
      content: "";
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }
  
    .checkbox-wrapper-2 input[type=checkbox] {
      cursor: default;
    }
  
    .checkbox-wrapper-2 .ikxBAC:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;
    }
  
    .checkbox-wrapper-2 .ikxBAC:checked {
      background-color: #DDC982;
    }
  
    .checkbox-wrapper-2 .ikxBAC:checked::after {
      background-color: #fff;
      left: 13px;
    }
  
    .checkbox-wrapper-2 :focus:not(.focus-visible) {
      outline: 0;
    }
  
    .checkbox-wrapper-2 .ikxBAC:checked:hover {
      background-color: #DDC982;
    }

    #newFilter{
      display: none;
       }
    @media only screen and (max-width: 600px) {

     #area{
      display: none;
     }

     #newFilter{
      display: none;
       }
     .mycontainer{
      margin-top: -20px;
     }
    }



.reponsiveQr{
  width: 300px;
  height: 300px;
}
@media(max-width:470px){

  .reponsiveQr{
    width: 100%;
    height: 100%;
  }

    }



    .sumir01{
      display: block;
    }
    

    @media(max-width:485px){

      .sumir01{
        display: block;
      }

    }
    @media(max-width:370px){

  
    .city{
      position: absolute;
      font-size: 12px;
      padding-left: 20px;
      margin: 0 0;
    }
      
      }