/* ==========================================================================
   #FONT
   ========================================================================== */
   .font-robo {
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
  }
  
  /* ==========================================================================
     #GRID
     ========================================================================== */
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .col-2 {
    width: -webkit-calc((100% - 60px) / 2);
    width: -moz-calc((100% - 60px) / 2);
    width: calc((100% - 60px) / 2);
  }
  
  @media (max-width: 767px) {
    .col-2 {
      width: 100%;
    }
  }
  
  /* ==========================================================================
     #BOX-SIZING
     ========================================================================== */
  /**
   * More sensible default box-sizing:
   * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
   */
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  * {
    padding: 0;
    margin: 0;
  }
  
  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  
  /* ==========================================================================
     #RESET
     ========================================================================== */
  /**
   * A very simple reset that sits on top of Normalize.css.
   */
  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin: 0;
    padding: 0;
  }
  
  /**
   * Remove trailing margins from nested lists.
   */
  li > ol,
  li > ul {
    margin-bottom: 0;
  }
  
  /**
   * Remove default table spacing.
   */
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  /**
   * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
   *    on fieldsets.
   */
  fieldset {
    min-width: 0;
    /* [1] */
    border: 0;
  }
  
  button {
    outline: none;
    background: none;
    border: none;
  }
  
  /* ==========================================================================
     #PAGE WRAPPER
     ========================================================================== */
  .page-wrapper {
    min-height: 100vh;
  }
  
  body {
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }
  
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 18px;
  }
  
  h5 {
    font-size: 15px;
  }
  
  h6 {
    font-size: 13px;
  }
  
  /* ==========================================================================
     #BACKGROUND
     ========================================================================== */
  .bg-blue {
    background: #2c6ed5;
  }
  
  /* ==========================================================================
     #SPACING
     ========================================================================== */
  .p-t-100 {
    padding-top: 100px;
  }
  
  .p-t-20 {
    padding-top: 20px;
  }
  
  .p-b-100 {
    padding-bottom: 100px;
  }
  
  /* ==========================================================================
     #WRAPPER
     ========================================================================== */
  .wrapper {
    margin: 0 auto;
  }
  
  .wrapper--w680 {
    max-width: 680px;
  }
  
  /* ==========================================================================
     #BUTTON
     ========================================================================== */
  .btn {
    line-height: 40px;
    display: inline-block;
    padding: 0 25px;
    cursor: pointer;
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
    color: #fff;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    font-size: 14px;
    font-weight: 700;
  }
  
  .btn--radius {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  
  .btn--green {
    background: #57b846;
  }
  
  .btn--green:hover {
    background: #4dae3c;
  }
  
  /* ==========================================================================
     #DATE PICKER
     ========================================================================== */
  td.active {
    background-color: #2c6ed5;
  }
  
  input[type="date" i] {
    padding: 14px;
  }
  
  .table-condensed td, .table-condensed th {
    font-size: 14px;
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400;
  }
  
  .daterangepicker td {
    width: 40px;
    height: 30px;
  }
  
  .daterangepicker {
    border: none;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    display: none;
    border: 1px solid #e0e0e0;
    margin-top: 5px;
  }
  
  .daterangepicker::after, .daterangepicker::before {
    display: none;
  }
  
  .daterangepicker thead tr th {
    padding: 10px 0;
  }
  
  .daterangepicker .table-condensed th select {
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px;
    outline: none;
  }
  
  /* ==========================================================================
     #FORM
     ========================================================================== */
  input {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: inherit;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 30px;
    border-bottom: 2px solid #ccc;
  }
  
  .input-icon {
    position: absolute;
    font-size: 18px;
    color: #ccc;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .input--textarea{
    border: none;
    width: 100%;
    outline: none;
  }

  .input--style-1 {
    padding: 9px 0;
    color: #666;
  }
  
  .input--style-1::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #555;
  }
  
  .input--style-1:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #555;
    opacity: 1;
  }
  
  .input--style-1::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #555;
    opacity: 1;
  }
  
  .input--style-1:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #555;
  }
  
  .input--style-1:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #555;
  }
  
  /* ==========================================================================
     #SELECT2
     ========================================================================== */
  .select--no-search .select2-search {
    display: none !important;
  }
  
  .rs-select2 .select2-container {
    width: 100% !important;
    outline: none;
  }
  
  .rs-select2 .select2-container .select2-selection--single {
    outline: none;
    border: none;
    height: 34px;
  }
  
  .rs-select2 .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 34px;
    padding-left: 0;
    color: #555;
  }
  
  .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow {
    height: 32px;
    right: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow b {
    display: none;
  }
  
  .rs-select2 .select2-container .select2-selection--single .select2-selection__arrow:after {
    font-family: "Material-Design-Iconic-Font";
    content: '\f2f9';
    font-size: 18px;
    color: #ccc;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .rs-select2 .select2-container.select2-container--open .select2-selection--single .select2-selection__arrow::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  
  .select2-container--open .select2-dropdown--below {
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #e0e0e0;
    margin-top: 5px;
    overflow: hidden;
  }
  
  /* ==========================================================================
     #TITLE
     ========================================================================== */
  .title {
    margin-bottom: 37px;
  }
  
  /* ==========================================================================
     #CARD
     ========================================================================== */
  .card {
    overflow: hidden;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
  }
  
  .card-1 {
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  }
  
  .card-1 .card-heading {

    padding-top: 210px;
  }
  
  .card-1 .card-body {
    padding: 0 90px;
    padding-top: 55px;
    padding-bottom: 65px;
  }
  
  @media (max-width: 767px) {
    .card-1 .card-body {
      padding: 0 40px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }




  .checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
  }

  .checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  .checkbox-wrapper-2 .ikxBAC,
  .checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
  }

  .checkbox-wrapper-2 .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
  }

  .checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
  }

  .checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
  }

  .checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #DDC982;
  }

  .checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
  }

  .checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
  }

  .checkbox-wrapper-2 .ikxBAC:checked:hover {
    background-color: #DDC982;
  }
